<template>
  <BaseModal
    v-bind="$attrs"
    @input="$emit('input', $event)"
  >
    <CardPopup
      class="w-600"
      :title="title"
      @hide="$emit('input', false)"
    >
      <template #body>
        <div
          v-if="!$slots['additional-message']"
          class="is-flex is-align-items-center"
        >
          <b-icon
            size="is-medium"
            icon="alert-circle"
            type="is-danger"
            class="mr-4"
          />
          <p class="is-size-6">
            This action cannot be undone.<br>
            This will permanently delete the <strong>{{ entityName }}</strong>.
          </p>
        </div>
        <slot name="additional-message" />
        <br>
        <div>
          <label class="is-block">Please type <strong>{{ entityName }}</strong> to confirm.</label>
          <b-input v-model="entityInput" />
        </div>
      </template>
      <template #footer>
        <div class="is-flex is-justify-content-space-between w-full">
          <b-button
            class="px-6 rounded-8 btn-primary-light"
            @click="$emit('input', false)"
          >
            Cancel
          </b-button>
          <b-button
            type="is-danger"
            class="px-6 rounded-8"
            :disabled="!isInputValid"
            @click="$emit('delete')"
          >
            Delete
          </b-button>
        </div>
      </template>
    </CardPopup>
  </BaseModal>
</template>

<script >
import { computed, defineAsyncComponent, ref, useAttrs, watch } from '@vue/composition-api';
const BaseModal = defineAsyncComponent(() => import('@/modules/core/components/generics/base-modal/BaseModal.vue'));
const CardPopup = defineAsyncComponent(() => import('@/modules/core/components/generics/base-modal/CardPopup.vue'));
const __sfc_main = {};
__sfc_main.props = {
  title: {
    type: String,
    default: 'Are you absolutely sure?',
    description: 'title of modal'
  },
  entityName: {
    type: String,
    default: '',
    description: 'Name of entity to be deleted.'
  },
  additionalMessage: {
    type: String,
    default: '',
    description: 'Optional additional message.'
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const attrs = useAttrs();

  //-- input logic --//
  const entityInput = ref('');
  const isInputValid = computed(() => entityInput.value === props.entityName);
  watch(() => attrs.value, () => {
    if (!attrs.value) {
      entityInput.value = '';
    }
  });
  return {
    entityInput,
    isInputValid
  };
};
__sfc_main.components = Object.assign({
  BaseModal,
  CardPopup
}, __sfc_main.components);
export default __sfc_main;
</script>
