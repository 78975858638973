<template>
  <div>
    <teleport selector="#appButton">
      <b-button
        class="px-6 add-new-btn btn-primary-light"
        @click="openLayoutModal()"
      >
        <b-icon
          icon="plus"
          size="is-small"
        />New layout
      </b-button>
    </teleport>

    <b-table
      v-if="layouts.length > 0"
      :data="layouts"
      class="tab-table-layout"
      @click="goToLayout($event)"
    >
      <b-table-column
        v-slot="{ row }"
        label="Name"
        field="name"
        width="40%"
      >
        {{ row.name }}
      </b-table-column>
      <b-table-column
        v-slot="{ row }"
        label=""
        cell-class="has-text-right"
      >
        <b-button
          size="is-small"
          class="app-dropdown-btn"
          @click.stop="toggleActionDropdown(row.id)"
        >
          <b-icon
            icon="dots-horizontal"
            size="is-size-6"
            class="app-dropdown-icon"
          />
        </b-button>
        <b-dropdown
          :ref="`layoutActionDropdown${row.id}`"
          aria-role="list"
          position="is-bottom-left"
          class="app-action-dropdown"
          append-to-body
        >
          <b-dropdown-item
            aria-role="listitem"
            class="is-flex is-align-items-center"
            @click="goToLayout(row)"
          >
            <b-icon
              icon="pencil-outline"
              type="is-black"
              custom-size="mdi-22px"
              class="mr-2"
            />
            Edit
          </b-dropdown-item>
          <b-dropdown-item
            aria-role="listitem"
            class="is-flex is-align-items-center"
            @click="copyLayout(row)"
          >
            <b-icon
              icon="content-copy"
              type="is-black"
              custom-size="mdi-22px"
              class="mr-2"
            />
            Duplicate
          </b-dropdown-item>
          <b-dropdown-item
            aria-role="listitem"
            custom
            class="is-flex is-align-items-center is-justify-content-center"
          >
            <b-button
              icon-left="delete-outline"
              size="is-small"
              type="is-danger is-light"
              class="rounded-8 w-full"
              @click.stop="confirmDelete(row)"
            >
              Delete
            </b-button>
          </b-dropdown-item>
        </b-dropdown>
      </b-table-column>
    </b-table>



    <NoResults
      v-else-if="layouts.length === 0"
      banner-label="No layouts yet, create one now."
      button-label="New layout"
      :button-handler="openLayoutModal"
    />

    <BaseModal
      v-model="layoutModalActivity"
      :has-modal-card="true"
      :trap-focus="true"
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="New module"
      aria-modal
    >
      <CardPopup
        title="New layout"
        class="w-800"
        @hide="layoutModalActivity = false"
      >
        <template #body>
          <b-field label="Layout name*"
          :type="layoutErrors.name? 'is-danger': ''"
          :message="layoutErrors.name? layoutErrors.name: ''"
          >
            <b-input
              v-model="layout.name"
              size="is-small"
              @keyup.native="validateLayout('name')"
            />
          </b-field>
        </template>
        <template #footer>
          <div class="is-flex is-justify-content-space-between w-full">
            <b-button
              class="px-6 rounded-8 btn-primary-light"
              @click="layoutModalActivity = false"
            >
              Cancel
            </b-button>
            <b-button
              type="is-primary"
              class="px-6 rounded-8"
              @click="createLayout()"
            >
              Create
            </b-button>
          </div>
        </template>
      </CardPopup>
    </BaseModal>
    <ConfirmDeleteModal
      v-model="isDeleteLayoutModalVisible"
      :entity-name="layoutToBeDeleted.name"
      :has-modal-card="true"
      :trap-focus="true"
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Confirm Delete Layout"
      aria-modal
      @delete="deleteLayout()"
    />
  </div>
</template>

<script >
// libs
import { computed, onMounted, ref, getCurrentInstance, reactive } from '@vue/composition-api';
// components
import BaseModal from '@/modules/core/components/generics/base-modal/BaseModal';
import CardPopup from '@/modules/core/components/generics/base-modal/CardPopup.vue';
import ConfirmDeleteModal from '@/modules/core/components/ConfirmDeleteModal.vue';
import NoResults from '@/modules/core/components/NoResults';
import * as Yup from 'yup';

// stores
import { useModuleStore } from '@/modules/builder/store/moduleStore';
// services
import { fetchLayoutService, createLayoutService, deleteLayoutService, copyLayoutService } from '@/services/application-service/layoutRequests';
// composables
import { useRoute, useRouter } from '@/hooks/vueRouter';
import { useBuefy } from '@/hooks/buefy';
import { refResetter } from '@/hooks/utils';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const vm = getCurrentInstance();

  // use composables 
  const route = useRoute();
  const router = useRouter();
  const buefy = useBuefy();
  const moduleStore = useModuleStore();

  //-- common --//
  const goToLayout = async tableRow => {
    const {
      id
    } = tableRow;
    await router.push('layouts/' + id);
  };
  const toggleActionDropdown = rowId => {
    // @VUE3: use functional template ref in vue 3 instead (check offical vue 3 docs for info), vue 2 composition api plugin doesn't support them
    vm.proxy.$refs[`layoutActionDropdown${rowId}`].toggle();
  };

  //-- fetch layouts --// 
  const layouts = ref([]);
  const layout = ref({
    name: '',
    data: ''
  });
  const fetchLayouts = async () => {
    try {
      const {
        data: {
          data
        }
      } = await fetchLayoutService(route.params.appId);
      layouts.value = data;
    } catch (err) {
      console.log(err);
    }
  };
  onMounted(() => {
    fetchLayouts();
  });

  // Layout validation
  const LayoutSchema = Yup.object().shape({
    name: Yup.string().required('Name is required.').min(2, 'Name must be at least 2 characters long.')
  });
  const layoutErrors = reactive({
    name: ''
  });
  const validateLayout = async field => {
    try {
      await LayoutSchema.validateAt(field, layout.value);
      layoutErrors[field] = '';
    } catch (err) {
      layoutErrors[err.path] = err.message;
    }
  };

  //-- create layout --//
  const layoutModalActivity = computed({
    get() {
      return moduleStore.isLayoutModalActive;
    },
    set(value) {
      moduleStore.setLayoutModalActivity(value);
    }
  });
  const createLayout = async () => {
    try {
      await LayoutSchema.validate(layout.value, {
        abortEarly: false
      });
    } catch (err) {
      err.inner.reverse().forEach(error => {
        layoutErrors[error.path] = error.message;
      });
      return false;
    }
    try {
      const {
        data: {
          data
        }
      } = await createLayoutService(route.params.appId, layout.value);
      layoutModalActivity.value = false;
      await router.push('layouts/' + data.id);
    } catch (err) {
      layoutModalActivity.value = false;
      console.log(err);
    }
  };
  const openLayoutModal = async () => {
    moduleStore.setLayoutModalActivity(true);
  };

  //-- delete layout --//
  const isDeleteLayoutModalVisible = ref(false);
  const [layoutToBeDeleted, resetLayoutToBeDeleted] = refResetter({
    id: '',
    string: ''
  });
  const confirmDelete = item => {
    layoutToBeDeleted.value.id = item.id;
    layoutToBeDeleted.value.name = item.name;
    toggleActionDropdown(item.id);
    isDeleteLayoutModalVisible.value = true;
  };
  const deleteLayout = async () => {
    try {
      await deleteLayoutService(route.params.appId, layoutToBeDeleted.value.id);
      resetLayoutToBeDeleted();
      isDeleteLayoutModalVisible.value = false;
      buefy.toast.open('Layout deleted!');
      await fetchLayouts();
    } catch (err) {
      console.error(err);
    }
  };

  //-- copy layout --//
  const copyLayout = async sourceLayout => {
    try {
      await copyLayoutService(route.params.appId, sourceLayout.id);
      await fetchLayouts();
    } catch (err) {
      console.error(err);
    }
  };
  return {
    goToLayout,
    toggleActionDropdown,
    layouts,
    layout,
    layoutErrors,
    validateLayout,
    layoutModalActivity,
    createLayout,
    openLayoutModal,
    isDeleteLayoutModalVisible,
    layoutToBeDeleted,
    confirmDelete,
    deleteLayout,
    copyLayout
  };
};
__sfc_main.components = Object.assign({
  NoResults,
  BaseModal,
  CardPopup,
  ConfirmDeleteModal
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
@import '~@/style/variables.scss';
@import '~@/style/components.scss';
</style>