// @ts-check
import { authInterceptor, createHttpServiceConfig } from '@/services/httpFactories';
import {
    APPLICATION_SERVICE_ENDPOINT,
    APPLICATION_RESOURCE_PATH,
    MODULE_RESOURCE_PATH,
    LAYOUT_RESOURCE_PATH,
    COPY_LAYOUT_RESOURCE_PATH
} from './applicationServiceConstants';

const layoutServiceConfig = {
    ...createHttpServiceConfig(APPLICATION_SERVICE_ENDPOINT, APPLICATION_RESOURCE_PATH),
    /**
     * @param {string} applicationId 
     * @param {string} [layoutId]
     */
    getLayoutResourcePath: (applicationId, layoutId = '') => `/${applicationId}${LAYOUT_RESOURCE_PATH}${layoutId ? '/' : ''}${layoutId}`
};

//-- request interceptors --//
layoutServiceConfig.addRequestInterceptor(authInterceptor);

/**
 * @param {string} applicationId 
 */
export const fetchLayoutService = (applicationId) => layoutServiceConfig.http({
    method: 'GET',
    url: layoutServiceConfig.getLayoutResourcePath(applicationId)
});

/**
 * @param {string} applicationId
 * @param {object} data
 */
export const createLayoutService = (applicationId, data) => layoutServiceConfig.http({
    method: 'POST',
    url: layoutServiceConfig.getLayoutResourcePath(applicationId),
    data
});

/**
 * @param {string} applicationId
 * @param {string} layoutId
 * @param {object} data
 * @param query
 */
export const updateLayoutService = (applicationId, layoutId, data, query = '') => layoutServiceConfig.http({
    method: 'PATCH',
    url: `${layoutServiceConfig.getLayoutResourcePath(applicationId, layoutId)}${query}`,
    data
});

/**
 * @param {string} applicationId 
 * @param {string} layoutId
 */
export const fetchLayoutByIdService = (applicationId, layoutId) => layoutServiceConfig.http({
    method: 'GET',
    url: layoutServiceConfig.getLayoutResourcePath(applicationId, layoutId)
});

/**
 * @param {string} applicationId 
 * @param {string} layoutId
 */
export const deleteLayoutService = (applicationId, layoutId) => layoutServiceConfig.http({
    method: 'DELETE',
    url: layoutServiceConfig.getLayoutResourcePath(applicationId, layoutId)
});

export const copyLayoutService = (applicationId, layoutId) => layoutServiceConfig.http({
    method: 'POST',
    url: `${layoutServiceConfig.getLayoutResourcePath(applicationId, layoutId)}${COPY_LAYOUT_RESOURCE_PATH}`
});
